import style from "./BoloAluguel.module.scss"
;
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { useBolosAluguel } from "../../../redux/sliceBolosAluguel";

import { Erro404 } from "../../Erro404"

import { DescricaoBolo } from "../../../components/Descricao";
import { Carousel } from "../../../components/Carousel";


function BoloAluguel() {
    const bolosAluguel = useSelector(useBolosAluguel);
    const pathBolo: string = useLocation().pathname.slice(9);
    const boloAluguel = bolosAluguel.find((element) => element.urlBolo === pathBolo);

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    if (!boloAluguel) {
        return <Erro404 />
    } else {
        return (
            <>
                <section className={style.bolo}>
                    <div className="container">
                        <div className="row justify-content-center align-items-stretch">
                            <div className="col-11 col-sm-10 col-md-6 col-lg-5 col-xl-4 mb-3 mb-sm-4 mb-md-0 d-flex align-items-center">
                                <Carousel
                                    urlImg={boloAluguel.urlImg}
                                />
                            </div>
                            <div className="col-11 col-md-6 offset-xl-1 col-xl-5 d-flex justify-content-center align-items-center">
                                <DescricaoBolo
                                    nome={boloAluguel.nome}
                                    descricao={boloAluguel.descricao}
                                    precoAluguel={boloAluguel.precoAluguel}
                                    linkBtn={`https://wa.me/5511993810758?text=Ol%C3%A1,%20gostaria%20de%20alugar%20o%20bolo%20${boloAluguel.nome}!`}
                                />
                            </div>

                        </div>
                    </div>
                </section>
            </>
        )
    }

}

export { BoloAluguel }